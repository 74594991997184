import { CapacitorHttp, HttpOptions, HttpResponse } from "@capacitor/core";
import baseHttpOptions from "../../util/http-common";
import { PrintReportParams, Stat } from "../../models/reportModels";

const getReportStats = async (userId?: number) => {
  let url: string;
  if (userId) {
    url = `report-doughnut?user_id=${userId}`;
  } else {
    url = `report-doughnut`;
  }

  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.get(options);

  const { data } = response;
  return data;
};



const getReports = async (
  userId: number,
  page?: number,
  amount?: number,
  state?: Stat
) => {
  let url: string = `report?user_id=${userId}`;

  if (page && amount) {
    url = url + `&amount=${amount}&page=${page}`;
  }

  if (state) {
    url = url + `&state=${JSON.stringify(state)}`;
  }

  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.get(options);

  const { data } = response;
  //console.log(data)
  return data;
};

const getReportWeekEntries = async (
  userId: number | string,
  year: number | string,
  week: number | string,
) => {
  let url: string = `report-week?user_id=${userId}&year=${year}&week=${week}`;
  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.get(options);
  const { data } = response;
  return data;
};

const setReportWeekState = async (
  id: number,
  state: string,
  message?: string
) => {
  let url: string = `set-report-state?id=${id}&state=${state}`;
  if (message) {
    url = url + `&message=${message}`;
  }
  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const createReportDayEntry = async (
  date: string,
  duration: string,
  activity: string
) => {
  const options: HttpOptions = await baseHttpOptions("entry/create");

  options.data = {
    date: date,
    duration: duration,
    activity: activity
  }

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const editReportDayEntry = async (
  id: number | string,
  duration: string,
  activity: string
) => {
  const options: HttpOptions = await baseHttpOptions("entry/edit");

  options.data = {
    id: id,
    duration: duration,
    activity: activity
  }

  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};

const deleteReportDayEntry = async (id: string | number) => {
  let url: string = `entry/delete?id=${id}`;
  const options: HttpOptions = await baseHttpOptions(url);
  const response: HttpResponse = await CapacitorHttp.post(options);
  const { data } = response;
  return data;
};



const printReport = async (reqData: PrintReportParams) => {
  let url = `report-print?start_year=${reqData.start_year}&start_week=${reqData.start_week}&end_year=${reqData.end_year}&end_week=${reqData.end_week}`;

  if (reqData.user_id) {
    url += `&user_id=${reqData.user_id}`;
  }

  if (reqData.state) {
    url += `&state=${reqData.state}`;
  }

  const options: HttpOptions = await baseHttpOptions(url);
  options.responseType = "blob"
  /* options.headers = {'Content-Type': 'application/pdf',} */

  const response: HttpResponse = await CapacitorHttp.get(options);
  //console.log(response)
  //const { data } = response;
  return response;
}

const ReportService = {
  getReportStats,
  getReports,
  getReportWeekEntries,
  setReportWeekState,
  createReportDayEntry,
  editReportDayEntry,
  deleteReportDayEntry,
  printReport
};

export default ReportService;
