import { IonAlert, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonLoading, IonPage, IonRow, isPlatform } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, PageContainer, ReportDayCard, RoundedButton, WeekSwitcher } from "../../components";
import { PrintReportParams, ReportDay, WeekSwitcherProps } from "../../models/reportModels";
/* import { useReportWeek } from "../../data/customHooks/useReportWeek"; */
import { useEffect, useState } from "react";
import { checkmarkCircleOutline, printOutline } from "ionicons/icons";
import ReportService from "../../data/services/ReportService";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { setReportWeek, updateReportWeekState } from "../../data/redux/reportWeekSlice";
import { format } from "date-fns";
import { changeReportListWeekState } from "../../data/redux/reportListSlice";
import { getReportStats } from "../../data/redux/reportStatsSlice";
import { downloadPDFInWeb, downloadPDFNative } from "../../util/pdf-helper";
import { setError } from "../../data/redux/errorSlice";
import { error500Text } from "../../util/error-helper";
import { getLatestReports } from "../../data/redux/latestReportsSlice";

interface RecordBookDetailProps extends RouteComponentProps<{
    year: string,
    week: string
}> { }

interface Params {
    year: string | number,
    week: string | number
}

const RecordBookDetailContent: React.FC<RecordBookDetailProps> = ({ match, history }) => {
    const [params, setParams] = useState<Params>(match.params)
    const { user } = useAppSelector((state) => state.user)
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [markAsFinishedAlertOpen, setMarkAsFinishedAlertOpen] = useState(false);
    const reportWeek = useAppSelector((state) => state.reportWeek.reportWeek);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    //const reportWeek = useReportWeek(user ? user.id : undefined, params.year, params.week)

    useEffect(() => {
        let ignore = false;

        const setCurrentReportWeek = async () => {
            const res = await ReportService.getReportWeekEntries(user.id, params.year, params.week)
            if (!ignore) {
                if (res && res.report) {
                    dispatch(setReportWeek(res.report))
                }
            }

        }

        if (user && params && params.year && params.week) {
            setCurrentReportWeek();
        }

        return () => {
            ignore = true;
        }
    }, [user, params, dispatch])

    const weekSwitcherCallback = (year: string | number, week: string | number) => {
        setParams({
            ...params,
            year: year,
            week: week
        })
        window.history.replaceState(null, "Berichtsheft", `/tabs/berichtsheft/${year}/${week}`)
    }

    const weekSwitcherProps: WeekSwitcherProps = {
        year: params.year,
        week: params.week,
        changeWeek: weekSwitcherCallback,
        state: reportWeek && reportWeek.state,
        trainingStart: user && (user.report_start === null ? user.trainee_start : user?.report_start)
    }

    const getStateButton = () => {
        if (reportWeek && user) {
            if (user.company_id) {
                if (reportWeek.state === "open" || reportWeek.state === "dismissed") {
                    return (
                        <RoundedButton type="button" onClick={() => setIsAlertOpen(true)}>
                            KW einreichen
                            <IonIcon icon={checkmarkCircleOutline} />
                        </RoundedButton>
                    )
                } else if (reportWeek.state === "submitted") {
                    return (
                        <RoundedButton type="button" fill="outline" disabled>
                            KW am {format(new Date(reportWeek.updated_at), "dd.MM.yy")} eingereicht
                            <IonIcon icon={checkmarkCircleOutline} />
                        </RoundedButton>
                    )
                }
                else if (reportWeek.state === "completed") {
                    return (
                        <RoundedButton type="button" fill="outline" disabled color="success">
                            KW am {format(new Date(reportWeek.updated_at), "dd.MM.yy")} akzeptiert
                            <IonIcon icon={checkmarkCircleOutline} />
                        </RoundedButton>
                    )
                }
                return null
            } else {
                if (reportWeek.state === "completed" || reportWeek.state === "submitted") {
                    return (
                        <RoundedButton type="button" fill="outline" disabled>
                            KW am {format(new Date(reportWeek.updated_at), "dd.MM.yy")} erledigt
                            <IonIcon icon={checkmarkCircleOutline} />
                        </RoundedButton>
                    )
                }
                else {
                    return (
                        <RoundedButton type="button" onClick={() => setMarkAsFinishedAlertOpen(true)}>
                            als erledigt markieren
                            <IonIcon icon={checkmarkCircleOutline} />
                        </RoundedButton>
                    )
                }
            }
        }
        return null;
    }

    const submitCalendarWeek = async () => {
        if (reportWeek && reportWeek.id) {
            const res = await ReportService.setReportWeekState(reportWeek.id, "submitted")
            //console.log(res)
            if (res.report) {
                dispatch(updateReportWeekState({ state: res.report.state, updated_at: res.report.updated_at }))
                dispatch(changeReportListWeekState({ state: res.report.state, updated_at: res.report.updated_at, id: res.report.id }))
                dispatch(getReportStats())
                dispatch(getLatestReports({userId: user.id}))
            }
        }
    }

    const printPDFForCurrentWeek = async () => {
        setLoading(true)
        let data: PrintReportParams = {
            start_week: params.week,
            start_year: params.year,
            end_week: params.week,
            end_year: params.year
        }

        try {
            const res = await ReportService.printReport(data);
            if (res.data.error) {
                dispatch(setError({
                    isOpen: true, message: res.data.error
                }))
            } else if (res.status === 200) {
                if (!isPlatform('mobile') || isPlatform('mobileweb')) {
                    downloadPDFInWeb(res.data)
                } else {
                    downloadPDFNative(res.data)
                }
            } else {
                dispatch(setError({
                    isOpen: true, message: error500Text
                }))
            }
        } catch (err) {
            dispatch(setError({
                isOpen: true, message: error500Text
            }))
        } finally {
            setLoading(false)
        }
    }

    return (
        <IonPage key={match.params.year + "+" + match.params.week}>
            <PageContainer
                name="Berichtsheft"
                backButton={true}
                weekSwitcherToolbar={true}
                weekSwitcherProps={weekSwitcherProps}
                otherButton={true}
                otherButtonIcon={printOutline}
                otherButtonAction={printPDFForCurrentWeek}
            >
                <IonGrid>

                    <BackButtonRow path="/tabs/berichtsheft" />

                    <IonRow>
                        <IonCol size="12" className="ion-hide-lg-down">
                            <IonCard>
                                <IonCardHeader>
                                    <div className="reportDesktopHeader">
                                        <div className="minWidthCol">
                                            <IonCardTitle className="headline">Berichtsheft</IonCardTitle>
                                        </div>
                                        <div>
                                            <WeekSwitcher week={params.week} year={params.year} changeWeek={weekSwitcherCallback} state={reportWeek && reportWeek.state} trainingStart={user && (user.report_start === null ? user.trainee_start : user.report_start)} />
                                        </div>
                                        <div className="minWidthCol" style={{ textAlign: "right" }}>
                                            <IonButton fill="clear" onClick={printPDFForCurrentWeek}>
                                                <IonIcon icon={printOutline} />
                                            </IonButton>
                                        </div>
                                    </div>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    {reportWeek && reportWeek.state === "dismissed" && reportWeek.lastMessage[0] && <IonRow>
                        <IonCol>
                            <IonCard color="danger">
                                <IonCardContent>
                                    {reportWeek.lastMessage[0].message}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>}

                    <IonRow>

                        {user && reportWeek && reportWeek.days.map((day: ReportDay) => {
                            return (
                                <IonCol size="12" key={day.id}>
                                    <ReportDayCard day={day} clickable={(!user.company_id || (reportWeek.state === "open" || reportWeek.state === "dismissed")) ? true : false} year={params.year} week={params.week} />
                                </IonCol>
                            )
                        })}

                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <div style={{ display: "flex", justifyContent: "flex-end", margin: 10 }}>
                                {getStateButton()}
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>

            <IonAlert
                isOpen={isAlertOpen}
                header={`KW${params.week} einreichen`}
                message="Die Kalenderwoche kann danach nicht mehr bearbeitet werden."
                buttons={[
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                        handler: () => setIsAlertOpen(false)
                    },
                    {
                        text: "Einreichen",
                        role: 'confirm',
                        handler: submitCalendarWeek
                    }
                ]}
                onDidDismiss={() => setIsAlertOpen(false)}
            />

            <IonAlert
                isOpen={markAsFinishedAlertOpen}
                header={`KW${params.week} als erledigt markieren`}
                message="Die Kalenderwoche kann danach noch bearbeitet werden. Verknüpfst du dich in der Zwischenzeit allerdings mit deinem Unternehmen, dann muss dein Ausbilder die Woche erst ablehnen, damit du sie wieder bearbeiten kannst."
                buttons={[
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                        handler: () => setMarkAsFinishedAlertOpen(false)
                    },
                    {
                        text: "Erledigt",
                        role: 'confirm',
                        handler: submitCalendarWeek
                    }
                ]}
                onDidDismiss={() => setMarkAsFinishedAlertOpen(false)}
            />
            <IonLoading
                isOpen={loading}
                message="Lädt..."
            />
        </IonPage>
    )
}

const RecordBookDetail: React.FC<RecordBookDetailProps> = (props: RecordBookDetailProps) => {
    return (
        <RecordBookDetailContent
            {...props}
            key={props.match.params.year + props.match.params.week}
        />
    )
}

export default RecordBookDetail;
