import { IonActionSheet, IonAlert, IonButton, IonCol, IonFab, IonFabButton, IonGrid, IonIcon, IonPage, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { PageContainer, ReportDayCard } from "../../components";
import { addOutline, arrowBackOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { addEntry, deleteEntry, editEntry, setReportDay } from "../../data/redux/reportDaySlice";
import DayEntryDialog from "./dayEntryDialog";
import ReportService from "../../data/services/ReportService";

import "./ReportBook.scss";
import { ReportEntry, ReportWeek } from "../../models/reportModels";
import { addReportWeekEntry, deleteReportWeekEntry, editReportWeekEntry, setReportWeek } from "../../data/redux/reportWeekSlice";
import { setError } from "../../data/redux/errorSlice";
import { error500Text } from "../../util/error-helper";

interface RecordBookDayProps extends RouteComponentProps<{
    year: string,
    week: string,
    dayId: string
}> { }

const RecordBookDay: React.FC<RecordBookDayProps> = ({ match }: RecordBookDayProps) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const { year, week, dayId } = match.params
    const { user } = useAppSelector((state) => state.user)
    const reportDay = useAppSelector((state) => state.reportDay.reportDay)
    const dispatch = useAppDispatch();
    const [isActionSheetOpen, setIsActionSheetOpen] = useState<boolean>(false);
    const selectedEntryRef = useRef<ReportEntry | undefined>(undefined)
    const reportWeekTest = useAppSelector((state) => state.reportWeek.reportWeek);
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false)

    useEffect(() => {
        let ignore = false;

        const getReportWeek = async (traineeId: number | string) => {
            const res = await ReportService.getReportWeekEntries(traineeId, year, week)
            if (!ignore) {
                if (res && res.report) {
                    //setEntries(res.report)
                    dispatch(setReportWeek(res.report))
                    setDayByWeek(res.report)
                }

            }
        }

        const setDayByWeek = (week: ReportWeek) => {
            if (week) {
                let find = week.days.find(x => String(x.id) === dayId)
                if (!ignore && find) {
                    dispatch(setReportDay(find))
                }
            }
        }

        if (reportWeekTest.id === null && user && user.id && year && week) {
            //console.log(reportWeekTest)
            getReportWeek(user.id);
        } else if (user && user.id) {
            if (String(reportWeekTest.year) !== year || String(reportWeekTest.week) !== week) {
                //console.log("in dem if")
                getReportWeek(user.id);
            }
            else if (!ignore) {
                setDayByWeek(reportWeekTest)
            }
        }

        return () => {
            ignore = true;
        }
    }, [user, year, week, /* reportWeekTest, */ dayId, dispatch])// eslint-disable-line react-hooks/exhaustive-deps


    const addCallback = async (duration: string, activity: string) => {
        try {
            const res = await ReportService.createReportDayEntry(reportDay.date, duration, activity)

            if (res.entry) { //success
                dispatch(addEntry(res.entry))
                dispatch(addReportWeekEntry(res.entry))
            } else if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
            }
        } catch (err) {
            console.log(err)
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
        }

    }

    const editCallBack = async (id: number, duration: string, activity: string) => {

        try {
            const res = await ReportService.editReportDayEntry(id, duration, activity);
            if (res.entry) {
                dispatch(editEntry(res.entry))
                dispatch(editReportWeekEntry(res.entry))
            } else if (res.error) {
                dispatch(setError({
                    isOpen: true,
                    message: res.error
                }))
            } else {
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
            }
        } catch (err) {
            console.log(err)
            dispatch(setError({
                isOpen: true,
                message: error500Text
            }))
        }
    }

    const handleEntryClick = (id: number) => {
        selectedEntryRef.current = reportDay.entries.find(x => x.id === id)
        setIsActionSheetOpen(true)
    }

    const handleDeleteClick = async () => {
        setDeleteAlertOpen(true);
    }

    const handleDelete = async () => {
        if (selectedEntryRef.current) {
            try {
                const res = await ReportService.deleteReportDayEntry(selectedEntryRef.current.id)
                //console.log(res)
                if (res.entry) {
                    dispatch(deleteEntry(res.entry))
                    dispatch(deleteReportWeekEntry(res.entry))
                    selectedEntryRef.current = undefined;
                } else if (res.error) {
                    dispatch(setError({
                        isOpen: true,
                        message: res.error
                    }))
                } else {
                    dispatch(setError({
                        isOpen: true,
                        message: error500Text
                    }))
                }

            } catch (err) {
                console.log(err)
                dispatch(setError({
                    isOpen: true,
                    message: error500Text
                }))
            }

        }
    }

    const handleEditClick = () => {
        if (selectedEntryRef.current) {
            setDialogOpen(true)
        }
    }

    const handleDialogClose = () => {
        if (selectedEntryRef) {
            selectedEntryRef.current = undefined
        }
        setDialogOpen(false);
    }


    return (
        <IonPage>
            <PageContainer name="Berichtsheft" backButton={true}>

                <IonGrid>

                    <IonRow className="ion-hide-lg-down" style={{ margin: "6px 10px 0 10px" }}>
                        <IonCol>
                            <IonButton fill="outline" routerLink={`/tabs/berichtsheft/${year}/${week}`}>
                                <IonIcon slot="start" icon={arrowBackOutline} /> zurück
                            </IonButton>
                        </IonCol>
                        <IonCol>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IonButton aria-label="Eintrag hinzufügen" onClick={() => setDialogOpen(true)}>
                                    <IonIcon icon={addOutline} />
                                </IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <ReportDayCard day={reportDay} onEntryClick={handleEntryClick} />
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </PageContainer>

            <IonFab aria-label="Eintrag hinzufügen" slot="fixed" vertical="bottom" horizontal="end" className="ion-hide-lg-up">
                <IonFabButton onClick={() => setDialogOpen(true)}>
                    <IonIcon icon={addOutline} />
                </IonFabButton>
            </IonFab>


            <DayEntryDialog isOpen={dialogOpen} handleClose={handleDialogClose} addCallback={addCallback} editCallback={editCallBack} entry={selectedEntryRef.current} key={selectedEntryRef.current?.id} />

            <IonActionSheet
                isOpen={isActionSheetOpen}
                header="Aktionen"
                onDidDismiss={() => setIsActionSheetOpen(false)}
                buttons={[
                    {
                        text: "Löschen",
                        role: "destructive",
                        handler: handleDeleteClick
                    }, {
                        text: 'Bearbeiten',
                        handler: handleEditClick
                    }, {
                        text: 'Abbrechen',
                        role: 'cancel',
                        handler: () => { selectedEntryRef.current = undefined }
                    },
                ]}
            />

            <IonAlert
                isOpen={isDeleteAlertOpen}
                header={`Eintrag löschen`}
                message={`Bist du sicher, dass du den Eintrag <b>${selectedEntryRef.current?.activity}</b> löschen möchtest?`}
                buttons={[
                    {
                        text: "Abbrechen",
                        role: "cancel"
                    }, {
                        text: "Löschen",
                        handler: handleDelete
                    },
                ]}
                onDidDismiss={() => setDeleteAlertOpen(false)}
            >
            </IonAlert>



        </IonPage >
    )
}

export default RecordBookDay;