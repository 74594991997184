import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonList, IonLoading, IonPage, IonRouterLink, IonRow, IonToast } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { RoundedButton } from "../../../components";
import { businessOutline, eyeOffOutline, eyeOutline, logInOutline, personOutline } from 'ionicons/icons';
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import "./Login.scss";
import { LoginFormData } from "../../../models/userModels";
import { useEffect, useState } from "react";
import { isValidEmail } from "../../../util/helper";
import Logo from "../components/Logo";
import Footer from "../components/Footer";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { login } from "../../../data/redux/userSlice";

const LoginFormContainer: React.FC<RouteComponentProps> = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: 335 }}>
                <LoginForm {...props} />
                <OtherLoginOptions />
            </div>
        </div>
    )
}

const LoginForm: React.FC<RouteComponentProps> = ({ history }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { control, handleSubmit, formState: { errors } } = useForm<LoginFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
    });

    const dispatch = useAppDispatch();


    const onSubmit: SubmitHandler<LoginFormData> = async (data: LoginFormData) => {
        //onLogin(data, history);
        const dataToSend = { data, history }
        /* @ts-ignore */
        dispatch(login(dataToSend)).unwrap()

    };

    const handleEmailValidation = (email: string) => {
        const isValid = isValidEmail(email);

        if (isValid) {
            return isValid;
        } else {
            return "Keine gültige E-Mail Adresse"
        }
    };

    return (
        <div>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <IonList lines="inset" className="loginList">
                    <IonItem className="loginInput">
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: { value: true, message: "Bitte geben Sie eine E-Mail-Adresse an" },
                                validate: { value: handleEmailValidation }
                            }}

                            render={({ field }) => <IonInput
                                label="E-Mail"
                                labelPlacement="floating"
                                type="email"
                                aria-invalid={!!errors.email}
                                /* @ts-ignore */
                                onIonChange={(e) => field.onChange(e.detail.value)}
                                onIonBlur={() => field.onBlur()}
                            />}
                        />
                    </IonItem>
                    {errors.email && <div className="errorText">{errors.email.message}</div>}
                    <IonItem className="loginInput">
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: { value: true, message: "Bitte geben Sie Ihr Passwort ein" }
                            }}

                            render={({ field }) => <IonInput
                                label="Passwort"
                                labelPlacement="floating"
                                aria-invalid={!!errors.password}
                                type={showPassword ? "text" : "password"}
                                /* @ts-ignore */
                                onIonChange={(e) => field.onChange(e.detail.value)}
                                onIonBlur={() => field.onBlur()}
                            />}
                        />

                        <IonButton fill="clear" aria-label="Passwort anzeigen" onClick={() => setShowPassword(!showPassword)}>
                            <IonIcon color="dark" aria-hidden="true" icon={showPassword ? eyeOffOutline : eyeOutline} />
                        </IonButton>
                    </IonItem>
                    {errors.password && <div className="errorText">{errors.password.message}</div>}
                </IonList>
                {/* Workaround weil shadow dom submit button onEnter kein submit event abfeuert */}
                <input type="submit" className="submit-enter" />

                <RoundedButton type="submit">
                    anmelden
                    <IonIcon icon={logInOutline} slot="end" />
                </RoundedButton>
            </form>
        </div>
    )
}

const OtherLoginOptions = () => {
    return (
        <div className="otherLoginOptions">
            <div><IonRouterLink color="dark" routerLink="/passwort-vergessen">Passwort vergessen?</IonRouterLink></div>
            <div className="registerLinks">
                <div style={{fontSize: 16, fontWeight: 600}}>Neu bei Woodbook?</div>
                <div className="registerLink"><IonIcon icon={personOutline}/><IonRouterLink color="dark" routerLink="/registrieren">Registrierung für Azubis</IonRouterLink></div>
                <div className="registerLink"><IonIcon icon={businessOutline}/><IonRouterLink color="dark" routerLink="/unternehmen">Registrierung für Unternehmen</IonRouterLink></div>
            </div>
        </div>
    )
}



const Login: React.FC<RouteComponentProps> = (props) => {
    const { loading, error, success } = useAppSelector((state) => state.user)

    useEffect(() => {
        if (success && !loading) {
            props.history.push("/tabs/dashboard")
        }
    }, [loading, props.history, success])


    return (
        <IonPage>
            <IonContent>
                <div className="loginContainer" >
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="12" sizeMd="5">
                                <Logo />
                            </IonCol>
                            <IonCol className="ion-hide-md-down" size="2">
                                <div className="specialBorderFading"></div>
                            </IonCol>
                            <IonCol>
                                <LoginFormContainer {...props} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </div>
                <Footer />
                <IonLoading
                    message={"Lädt..."}
                    isOpen={loading}
                />

                <IonToast
                    message={error ? error : ""}
                    color="danger"
                    duration={3000}
                    isOpen={error !== undefined ? true : false}
                />

            </IonContent>

        </IonPage>
    )

}

export default Login
