import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { ReportDay } from "../../models/reportModels";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { calcTotalTime } from "../../util/report-helper";

interface ReportDayCardProps {
    day: ReportDay,
    clickable?: boolean,
    year?: string | number,
    week?: string | number,
    onEntryClick?: (id: number) => void
}

const ReportDayCard: React.FC<ReportDayCardProps> = ({ day, clickable, year, week, onEntryClick }: ReportDayCardProps) => {
    return (
        <IonCard className="reportCard" routerLink={clickable && week && year ? `/tabs/berichtsheft/${year}/${week}/${day.id}` : undefined}>
            <IonCardHeader >
                <IonCardTitle>
                    {day.date !== "" && format(new Date(day.date), "eeee, dd.MM.yyyy", { locale: de })}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <div className="entryContainer">
                    {day.entries.map(entry => {
                        return (
                            <div onClick={onEntryClick ? () => onEntryClick(entry.id) : undefined} key={entry.id} className="entry entryBorder" style={onEntryClick && { cursor: "pointer" }}>
                                <div className="entryDescription">{entry.activity}</div>
                                <div>{entry.duration.substring(0, 5)}</div>
                            </div>
                        )
                    })}
                    <div className="entry">
                        <div><b>Gesamt</b></div>
                        <div><b>{new Date(calcTotalTime(day) * 1000).toISOString().substring(11, 16)}</b></div>
                    </div>
                </div>
            </IonCardContent>
        </IonCard>
    )
}


export default ReportDayCard;