import { IonAlert, IonButton, IonCard, IonCardContent, IonCheckbox, IonDatetime, IonDatetimeButton, IonItem, IonLabel, IonList, IonModal, IonRadio, IonRadioGroup, IonText } from "@ionic/react"
import { ReportFormData, User } from "../../../../models/userModels";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { format } from "date-fns";
import { useAppDispatch } from "../../../../data/redux/hooks";
import { copyRequiredUserData } from "../../../../util/profile-helper";
import { editUser } from "../../../../data/redux/userSlice";
import { getReportStats } from "../../../../data/redux/reportStatsSlice";
import ReportService from "../../../../data/services/ReportService";
import { setReportList } from "../../../../data/redux/reportListSlice";
import { getLatestReports } from "../../../../data/redux/latestReportsSlice";
import { useRef, useState } from "react";

interface ReportSettingsFormProps {
    user: User | undefined
}

interface AlertProps {
    isOpen: boolean,
    message: string
}

const ReportSettingsForm: React.FC<ReportSettingsFormProps> = ({ user }: ReportSettingsFormProps) => {
    const { control, handleSubmit, watch, formState: { errors }, setValue } = useForm<ReportFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            differentStart: (user && user.report_start === null) ? false : true,
            report_start: (user && user.report_start !== null) ? user.report_start : undefined,
            /* report_type: (user && user.report_type !== null) ? user.report_type : undefined */
        }
    });
    const dispatch = useAppDispatch();
    const [alert, setAlert] = useState<AlertProps>({ isOpen: false, message: "" })
    const selectedReportType = useRef<string>();

    const selectedDifferentStart = watch("differentStart");

    const fetchReports = async (userId: number) => {
        const res = await ReportService.getReports(userId, 1, 25);
        if (res.report) {
            dispatch(setReportList(res.report.data))
        }
    }

    const onSubmit: SubmitHandler<ReportFormData> = async (data: ReportFormData) => {
        let userClone = copyRequiredUserData(user!);
        if (data.differentStart === false || data.report_start === undefined) {
            userClone.report_start = null
        } else {
            userClone.report_start = format(new Date(data.report_start!), "yyyy-MM-dd")
        }
        /* userClone.report_type = data.report_type; */
        dispatch(editUser(userClone)).then(async () => {
            dispatch(getReportStats())
            dispatch(getLatestReports({ userId: userClone.id }))
            fetchReports(userClone.id)
        })

    }

    const onReportTypeChange = (e) => {
        const value = e.detail.value;
        selectedReportType.current = value;
        if (value === "daily") {
            setAlert({ isOpen: true, message: "Folgende Auswirkungen bei Änderung von wöchentlich auf täglich" })
        } else if (value === "weekly") {
            setAlert({ isOpen: true, message: "Folgende Auswirkungen bei Änderung von täglich auf wöchentlich" })
        }

    }

    const confirmReportTypeChange = () => {
        setValue("report_type", selectedReportType.current)
    }

    return (
        <>
            <IonCard>
                <IonCardContent>
                    <div>
                        <h3><b>Berichtsheft-Einstellungen</b></h3>
                    </div>
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <IonList>

                            <IonItem className="registerInput">
                                <Controller
                                    name="differentStart"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <IonCheckbox checked={field.value} onIonChange={(e) => field.onChange(e.detail.checked)} justify="start" labelPlacement="end"><span className="ion-text-wrap">Berichtsheft soll nicht mit dem Ausbildungsstart beginnen.</span></IonCheckbox>
                                        )
                                    }}
                                />
                            </IonItem>
                            <div className="errorText">{errors.differentStart?.message}</div>

                            {selectedDifferentStart === true &&
                                <>
                                    <IonItem className="registerInput">
                                        <IonLabel>Berichtsheft-Beginn</IonLabel>
                                        <Controller
                                            name="report_start"
                                            control={control}
                                            /* @ts-ignore */
                                            render={({ field }) => <>
                                                <IonDatetimeButton datetime="report_start"></IonDatetimeButton>

                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime
                                                        presentation="date"
                                                        id="report_start"
                                                        firstDayOfWeek={1}
                                                        aria-invalid={!!errors.report_start}
                                                        onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                        showDefaultButtons={true}
                                                        doneText="OK"
                                                        cancelText="Abbrechen"
                                                        value={field.value}
                                                    ></IonDatetime>
                                                </IonModal>
                                            </>}
                                        />
                                    </IonItem>
                                    <div className="errorText">{errors.report_start?.message}</div>
                                </>
                            }
                        </IonList>


                        {/* <IonText>Berichtsheft-Typ</IonText>
                        <div style={{ marginBottom: 30 }}>
                            <Controller
                                name="report_type"
                                control={control}
                                render={({ field }) =>
                                    <IonRadioGroup
                                        value={field.value}
                                        onIonChange={(e) => onReportTypeChange(e)}
                                    >
                                        <IonItem className="registerInput">
                                            <IonRadio value="daily">täglich</IonRadio>
                                        </IonItem>
                                        <IonItem className="registerInput">
                                            <IonRadio value="weekly">wöchentlich</IonRadio>
                                        </IonItem>

                                    </IonRadioGroup>
                                }
                            />
                        </div> */}




                        <div style={{ textAlign: "right" }}>
                            <IonButton type="submit">Speichern</IonButton>
                        </div>
                    </form>
                </IonCardContent>
            </IonCard>
            <IonAlert
                isOpen={alert.isOpen}
                header="Bist du dir sicher?"
                message={alert.message}
                buttons={[
                    {
                        text: 'Nein',
                        cssClass: 'alert-button-cancel',
                        role: "cancel"
                    },
                    {
                        text: 'Ja',
                        cssClass: 'alert-button-confirm',
                        handler: confirmReportTypeChange
                    },
                ]}
                onDidDismiss={() => setAlert({ isOpen: false, message: "" })}
            ></IonAlert>
        </>
    )
}

export default ReportSettingsForm