import { IonCard, IonCardContent, IonCol, IonGrid, IonItem, IonLabel, IonList, IonPage, IonRow, IonToggle } from "@ionic/react"
import { RouteComponentProps } from "react-router"
import { BackButtonRow, PageContainer } from "../../components"
import { useTheme } from "../../data/context/ThemeContext"

interface SettingsProps extends RouteComponentProps<{
    kat: string
}> { }



const Settings: React.FC<SettingsProps> = () => {

    const { theme, changeTheme } = useTheme();

    const handleToggleChange = () => {
        changeTheme(theme === "dark" ? "light" : "dark")
    }

    const themeChecker = theme === "dark" ? true : false

    return (
        <IonPage>
            <PageContainer name="Einstellungen" backButton={true}>
                <IonGrid>
                <BackButtonRow path="/tabs/profil" />
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    <IonList>
                                        <IonItem>
                                            <IonLabel>
                                                <h3>Versionsnummer</h3>
                                                <p>1.0.8</p>
                                            </IonLabel>
                                        </IonItem>

                                        <IonItem>
                                            <IonToggle checked={themeChecker} onIonChange={handleToggleChange}><h3>Dark Mode</h3></IonToggle>
                                        </IonItem>
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>
        </IonPage>


    )
}

export default Settings;