import { IonCheckbox, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow } from "@ionic/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { TrainingFormData } from "../../../models/userModels";
import { RoundedButton } from "../../../components";
import { arrowForwardOutline } from "ionicons/icons";
import { differenceInYears, format, formatISO } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { editUser } from "../../../data/redux/userSlice";
import { copyRequiredUserData } from "../../../util/profile-helper";

import "./EducationForm.scss";

const EducationFormPage: React.FC<RouteComponentProps> = () => {
    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm<TrainingFormData>({
        mode: "onTouched",
        reValidateMode: "onChange"
    });
    const { user, loading} = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch();

    const selectedDifferentStart = watch("differentStart");

    const onSubmit: SubmitHandler<TrainingFormData> = (data: TrainingFormData) => {
        if (user) {
            let editedUser = copyRequiredUserData(user);

            if (data.job !== undefined) {
                editedUser.job = data.job
            }

            if (data.trainee_end !== undefined) {
                editedUser.trainee_end = formatDateForBackend(data.trainee_end);
            }

            editedUser.trainee_start = formatDateForBackend(data.trainee_start)
            editedUser.trainee_year = data.trainee_year;

            if (data.differentStart === false) {
                editedUser.report_start = null;
            } else if (data.differentStart === true && data.report_start) {
                editedUser.report_start = formatDateForBackend(data.report_start)
            }

            //console.log(editedUser)
            dispatch(editUser(editedUser))
        }
    };

    const formatDateForBackend = (date: string) => {
        return format(new Date(date), "yyyy-MM-dd")
    }

    const onStartDateChange = (e: any, field: any) => {
        field.onChange(e.detail.value)
        setValue("trainee_year", calcTraineeYear(e.detail.value), { shouldValidate: true })
    }

    const calcTraineeYear = (startDate: Date) => {
        const currentDate = new Date();
        const res = differenceInYears(currentDate, new Date(startDate));
        if (res <= 0) {
            return 1
        } return res
    }

    const maxYear = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 20);
        return String(formatISO(date));
    }


    return (
        <IonPage>
            <IonContent>
                <div className="imageContainer" style={{display: "flex", justifyContent: "center"}}>
                    <IonImg
                        src="./assets/images/logo_woodbook_login_dark.png"
                        alt="Woodbook Logo"
                        style={{maxWidth: 400}}
                    ></IonImg>
                </div>
                <div className="educationFormContainer">
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="educationForm">
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <h3>Erzähl uns etwas über deine Ausbildung. Danach kannst du direkt loslegen.</h3>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonList>
                                    <IonItem className="registerInput">
                                        <Controller
                                            name="job"
                                            control={control}
                                            /* @ts-ignore */
                                            render={({ field }) => <IonInput
                                                label="Beruf"
                                                labelPlacement="floating"
                                                type="text"
                                                aria-invalid={!!errors.job}
                                                onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                onIonBlur={() => field.onBlur()}
                                            />}
                                        />
                                    </IonItem>
                                    <div className="errorText">{errors.job?.message}</div>


                                    <IonItem className="registerInput">
                                        <IonLabel>Ausbildungsstart*</IonLabel>
                                        <Controller
                                            name="trainee_start"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "Bitte gib den Beginn deiner Ausbildung an." }

                                            }}
                                            /* @ts-ignore */
                                            render={({ field }) => <>
                                                <IonDatetimeButton datetime="trainee_start"></IonDatetimeButton>

                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime
                                                        presentation="date"
                                                        id="trainee_start"
                                                        firstDayOfWeek={1}
                                                        aria-invalid={!!errors.trainee_start}
                                                        /* onIonChange={(e: any) => field.onChange(e.detail.value)} */
                                                        onIonChange={(e: any) => onStartDateChange(e, field)}
                                                        showDefaultButtons={true}
                                                        doneText="OK"
                                                        cancelText="Abbrechen"
                                                        value={field.value}
                                                    ></IonDatetime>
                                                </IonModal>
                                            </>}
                                        />
                                    </IonItem>
                                    <div className="errorText">{errors.trainee_start?.message}</div>


                                    <IonItem className="registerInput">
                                        <Controller
                                            name="trainee_year"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "Bitte gib an, in welchem Lehrjahr du bist." }
                                            }}
                                            /* @ts-ignore */
                                            render={({ field }) => <IonInput
                                                label="Ausbildungsjahr*"
                                                labelPlacement="floating"
                                                type="number"
                                                aria-invalid={!!errors.trainee_year}
                                                onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                onIonBlur={() => field.onBlur()}
                                                value={field.value}
                                            />}
                                        />
                                    </IonItem>
                                    <div className="errorText">{errors.trainee_year?.message}</div>

                                    <IonItem className="registerInput">
                                        <IonLabel>Ausbildungsende</IonLabel>
                                        <Controller
                                            name="trainee_end"
                                            control={control}
                                            /* @ts-ignore */
                                            render={({ field }) => <>
                                                <IonDatetimeButton datetime="trainee_end"></IonDatetimeButton>

                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime
                                                        presentation="date"
                                                        id="trainee_end"
                                                        firstDayOfWeek={1}
                                                        aria-invalid={!!errors.trainee_end}
                                                        onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                        showDefaultButtons={true}
                                                        doneText="OK"
                                                        cancelText="Abbrechen"
                                                        value={field.value}
                                                        max={maxYear()}
                                                    ></IonDatetime>
                                                </IonModal>
                                            </>}
                                        />
                                    </IonItem>
                                    <div className="errorText">{errors.trainee_end?.message}</div>

                                    <IonItem className="registerInput">
                                        <Controller
                                            name="differentStart"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <IonCheckbox
                                                        checked={field.value}
                                                        onIonChange={(e) => field.onChange(e.detail.checked)}
                                                        justify="start"
                                                        labelPlacement="end"
                                                    >
                                                        <span className="ion-text-wrap">Berichtsheft soll nicht mit dem Ausbildungsstart beginnen.</span>
                                                    </IonCheckbox>
                                                )
                                            }}
                                        />
                                    </IonItem>
                                    <div className="errorText">{errors.differentStart?.message}</div>

                                    {selectedDifferentStart === true &&
                                        <>
                                            <IonItem className="registerInput">
                                                <IonLabel>Berichtsheft-Beginn*</IonLabel>
                                                <Controller
                                                    name="report_start"
                                                    control={control}
                                                    rules={{
                                                        required: { value: selectedDifferentStart, message: "Pflichtfeld" }
                                                    }}
                                                    /* @ts-ignore */
                                                    render={({ field }) => <>
                                                        <IonDatetimeButton datetime="report_start"></IonDatetimeButton>

                                                        <IonModal keepContentsMounted={true}>
                                                            <IonDatetime
                                                                presentation="date"
                                                                id="report_start"
                                                                firstDayOfWeek={1}
                                                                aria-invalid={!!errors.report_start}
                                                                onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                                showDefaultButtons={true}
                                                                doneText="OK"
                                                                cancelText="Abbrechen"
                                                                value={field.value}
                                                            ></IonDatetime>
                                                        </IonModal>
                                                    </>}
                                                />
                                            </IonItem>
                                            <div className="errorText">{errors.report_start?.message}</div>
                                        </>
                                    }
                                    </IonList>

                                    <div className="requiredHelper">*Pflichtfeld</div>

                                    <div className="btnContainer">
                                        <RoundedButton type="submit">
                                            weiter
                                            <IonIcon icon={arrowForwardOutline} slot="end" />
                                        </RoundedButton>
                                    </div>

                                </IonCol>

                            </IonRow>
                        </IonGrid>
                    </form>
                </div>

                <IonLoading message="Lädt..." isOpen={loading} />

            </IonContent>
        </IonPage>
    )
}

export default EducationFormPage