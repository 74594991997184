//@ts-nocheck 
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, PageContainer } from "../../components";
import { useNewsArticle } from "../../data/customHooks/useNewsArticle";
import { register } from 'swiper/element/bundle';

interface NewsDetailProps extends RouteComponentProps<{
    id: string
}> { }


const NewsDetail: React.FC<NewsDetailProps> = ({ match }) => {
    const { id } = match.params;
    const newsArticle = useNewsArticle(id);
    const swiperRef = useRef<HTMLLinkElement | any>(null);

    useEffect(() => {
        // Register Swiper web component
        register();

        if (swiperRef.current && newsArticle) {
            // Object with parameters
            const params = {
                pagination: true,
                paginationClickable: true,
                navigation: true,
                /* slidesPerView: 3,
                breakpoints: {
                    768: {
                        slidesPerView: 4,
                    },
                }, */
            };

            // Assign it to swiper element
            Object.assign(swiperRef.current, params);

            // initialize swiper
            swiperRef.current.initialize();
        }

    }, [newsArticle]);

    return (
        <IonPage>
            <PageContainer name="News" backButton={true}>
                <IonGrid>
                    <BackButtonRow />

                    <IonRow className="ion-hide-lg-down">
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle className='headline'>News</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {newsArticle &&
                                <IonCard key={id}>
                                    <div className="swiperContainer">

                                        <swiper-container init="false" ref={swiperRef} >
                                            {newsArticle.images?.map(image => {
                                                return (
                                                    <swiper-slide key={image.id} >
                                                        <img src={image.img} alt="Newsartikel-Bild" />
                                                    </swiper-slide>
                                                )
                                            })}
                                        </swiper-container>
                                    </div>

                                    <IonCardHeader>
                                        <IonCardTitle>
                                            {newsArticle?.headline}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent className="formattedText">
                                        <div dangerouslySetInnerHTML={{ __html: newsArticle.text }}></div>
                                    </IonCardContent>
                                </IonCard>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>
        </IonPage>
    )
}

export default NewsDetail